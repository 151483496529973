<template>
  <VContainer
    fluid
    class="pa-4 pb-14"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol>
                <h1
                  data-test="title-form"
                  class="tt-text-headline-1"
                >
                  {{ edit ? 'Редактирование' : 'Добавление' }} push-уведомления
                </h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <VForm
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Название"
                        placeholder="Укажите название"
                        hint="Будет отображено в приложении и в личном кабинете"
                        persistent-hint
                        :value="entity.name"
                        :error="validation('name').status"
                        :error-messages="validation('name').value"
                        data-test="push-form-input-name"
                        @input="v => handleChange('name',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Заголовок push-уведомления"
                        placeholder="Укажите заголовок"
                        hint="Будет отправлен пользователю в пуш-уведомлении"
                        persistent-hint
                        :value="entity.msg_title"
                        :error="validation('msg_title').status"
                        :error-messages="validation('msg_title').value"
                        data-test="push-form-input-msg-title"
                        @input="v => handleChange('msg_title',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <TTTextarea
                        :maxlength="10000"
                        auto-grow
                        :rows="4"
                        row-height="14"
                        class="mb-2"
                        label="Текст push-уведомления"
                        hide-details="auto"
                        placeholder="Введите текст"
                        :value="entity.msg_text"
                        :error="validation('msg_text').status"
                        :error-messages="validation('msg_text').value"
                        data-test="push-form-input-msg-text"
                        @input="v => handleChange('msg_text',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <SDatePicker
                        label="Начало"
                        attach
                        class="mb-2"
                        append-icon="fal fa-calendar-alt"
                        placeholder="ДД.ММ.ГГГГ"
                        :value="entity.start_at"
                        :error="validation('start_at').status"
                        :error-messages="validation('start_at').value"
                        :min-date="dateNow"
                        :max-date="maxStartDate"
                        single-line
                        hide-details="auto"
                        data-test="push-form-input-date-start-at"
                        @input="v => handleChange('start_at',v)"
                      />
                    </VCol>
                    <VCol>
                      <SDatePicker
                        label="Окончание"
                        attach
                        class="mb-2"
                        append-icon="fal fa-calendar-alt"
                        placeholder="ДД.ММ.ГГГГ"
                        :value="entity.end_at"
                        :error="validation('end_at').status"
                        :error-messages="validation('end_at').value"
                        :min-date="minEndDate"
                        :max-date="maxDate"
                        single-line
                        hide-details="auto"
                        data-test="push-form-input-date-end-at"
                        @input="v => handleChange('end_at',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <TTSelect
                        v-model="entity.push_action"
                        large
                        label="Действия"
                        :items="actions"
                        item-text="label"
                        data-test="push-form-select-push-action"
                        attach
                      />
                    </VCol>
                    <VCol>
                      <TTTextField
                        large
                        label="Актуальность, ч"
                        placeholder="Укажите количество часов"
                        :value="entity.expires_hour || ''"
                        :error="validation('expires_hour').status"
                        :error-messages="validation('expires_hour').value"
                        type="number"
                        data-test="push-form-input-expires-hour"
                        @change="v => handleChange('expires_hour',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <VCheckbox
                        v-model="entity.is_active"
                        class="mt-0"
                        label="Активная"
                        hide-details="auto"
                        data-test="push-form-checkbox-is-active"
                        :ripple="false"
                      />
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <h2 class="mb-4">
                    Условия
                  </h2>
                  <VRow>
                    <VCol cols="6">
                      <TTSelect
                        large
                        label="Условие"
                        :value="conditionsValue"
                        :items="conditions"
                        item-text="label"
                        data-test="push-form-select-condition-type"
                        :error="validation('condition_type').status"
                        :error-messages="validation('condition_type').value"
                        @change="handleConditions"
                      />
                    </VCol>
                    <VCol cols="6">
                      <div class="flex-column">
                        <div
                          v-if="conditionsValue === 'level_id' || conditionsValue === 'task_id'"
                        >
                          <TTSelect
                            v-model="entity.condition_level_id"
                            large
                            label="На уровне"
                            placeholder="Выберете уровень"
                            :items="сutLevelList"
                            item-value="id"
                            item-text="name"
                            data-test="push-form-select-condition-level-id"
                          />
                        </div>
                        <div
                          v-if="conditionsValue === 'task_id'"
                          class="mt-6"
                        >
                          <TTSelect
                            v-model="entity.condition_task_id"
                            large
                            label="После задачи"
                            placeholder="Выберете задачу"
                            :items="filteredTaskList"
                            item-value="id"
                            item-text="name"
                            data-test="push-form-select-condition-task-id"
                          />
                        </div>
                        <div
                          v-if="conditionsValue === 'idle_time_hour'"
                        >
                          <TTTextField
                            v-model="entity.condition_idle_time_hour"
                            v-mask="'####'"
                            append-icon="fal fa-clock"
                            large
                            label="После бездействия, ч."
                            placeholder="Укажите количество часов"
                            data-test="push-form-input-condition-idle-time-hour"
                          />
                        </div>
                        <div
                          v-if="conditionsValue === 'start_time_hour'"
                        >
                          <TTTextField
                            v-model="entity.condition_start_time_hour"
                            v-mask="'####'"
                            large
                            label="После выхода, ч."
                            placeholder="Укажите количество часов"
                            append-icon="fal fa-clock"
                            data-test="push-form-input-condition-start-time-hour"
                          />
                        </div>
                      </div>
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <h2 class="mb-4">
                    Расписание
                  </h2>
                  <VRow>
                    <VCol cols="6">
                      <TTSelect
                        :value="schedulesValue"
                        :items="schedules"
                        large
                        label="Расписание"
                        item-text="label"
                        data-test="push-form-select-schedules-type"
                        :error="validation('schedule_type').status"
                        :error-messages="validation('schedule_type').value"
                        @change="handleSchedules"
                      />
                    </VCol>
                    <VCol cols="6">
                      <div class="flex-column">
                        <div
                          v-if="schedulesValue === 'week_day'"
                          class="mb-6"
                        >
                          <div class="tt-text-body-2 mb-3">
                            Дни недели
                          </div>
                          <VBtnToggle
                            class="day-of-week-btns"
                            :value="entity.schedule_week_day"
                            tile
                            multiple
                            borderless
                            color="tt-black"
                            data-test="push-form-buttons-schedule-week-day"
                            @change="v => handleChange('schedule_week_day', v, 'day')"
                          >
                            <VBtn
                              :value="1"
                              text
                              data-test="button-mon"
                              class="mr-2 mt-0 mb-0"
                            >
                              Пн
                            </VBtn>
                            <VBtn
                              :value="2"
                              text
                              data-test="button-tue"
                              class="mr-2 mt-0 mb-0"
                            >
                              Вт
                            </VBtn>
                            <VBtn
                              :value="3"
                              text
                              data-test="button-wed"
                              class="mr-2 mt-0 mb-0"
                            >
                              Ср
                            </VBtn>
                            <VBtn
                              :value="4"
                              text
                              data-test="button-thu"
                              class="mr-2 mt-0 mb-0"
                            >
                              Чт
                            </VBtn>
                            <VBtn
                              :value="5"
                              text
                              data-test="button-fri"
                              class="mr-2 mt-0 mb-0"
                            >
                              Пт
                            </VBtn>
                            <VBtn
                              :value="6"
                              text
                              data-test="button-sat"
                              class="mr-2 mt-0 mb-0"
                            >
                              Сб
                            </VBtn>
                            <VBtn
                              :value="7"
                              text
                              data-test="button-sun"
                              class="mt-0 mb-0"
                            >
                              Вс
                            </VBtn>
                          </VBtnToggle>
                          <div
                            v-if="validation('day').status"
                            class="v-messages error--text mt-2"
                            role="alert"
                            data-test="push-form-error-text-day"
                          >
                            <div class="v-messages__wrapper">
                              <p class="v-messages__message">
                                {{ validation('day').value }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="schedulesValue === 'month_day'"
                          class="mb-6"
                        >
                          <TTTextField
                            v-model="entity.schedule_month_day"
                            large
                            label="Дни месяца через запятую"
                            placeholder="Укажите дни"
                            :rules="monthRules"
                            data-test="push-form-input-schedule-month-day"
                          />
                        </div>
                        <div
                          v-if="schedulesValue === 'date'"
                          class="mb-6"
                        >
                          <SDatePicker
                            attach
                            placeholder="Укажите дату"
                            label="Дата"
                            :value="entity.schedule_date || ''"
                            :error="validation('day').status"
                            :error-messages="validation('day').value"
                            :min-date="dateNow"
                            single-line
                            hide-details="auto"
                            data-test="push-form-input-date-schedule-date"
                            @input="v => handleChange('schedule_date',v, 'day')"
                          />
                        </div>
                        <div
                          v-if="schedulesValue"
                        >
                          <CtxTimePicker
                            attach
                            placeholder="--:--"
                            append-icon="fal fa-clock"
                            label="Время"
                            :value="entity.schedule_time"
                            :minute-interval="1"
                            :error="validation('time').status"
                            :error-messages="validation('time').value"
                            single-line
                            hide-details="auto"
                            data-test="push-form-input-time-schedule-time"
                            @input="v => handleChange('schedule_time',v,'time')"
                          />
                        </div>
                      </div>
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <VRow>
                    <VCol>
                      <div class="d-flex justify-start">
                        <TTBtn
                          color="tt-secondary"
                          large
                          depressed
                          class="mr-6"
                          data-test="push-form-button-close"
                          @click="() => handleClose()"
                        >
                          Отменить
                        </TTBtn>
                        <TTBtn
                          large
                          depressed
                          data-test="push-form-button-save"
                          :disabled="!valid"
                          :loading="loading"
                          @click="save"
                        >
                          {{ edit ? 'Редактировать' : 'Добавить' }} пуш-уведомление
                        </TTBtn>
                      </div>
                    </VCol>
                  </VRow>
                </VForm>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="push-form-button-close-form"
              @click="() => handleClose()"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
/* eslint-disable camelcase */

import { mask } from 'vue-the-mask';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { validation, handleError, watchValidationStatus } from '@/services';
import { formatDate } from '@/utils';
import * as snamiApi from '@/services/backend/snami';
import { conditions, schedules } from '@/components/forms/pushData';
import { dictionaryKeys } from '@/constants';

import SDatePicker from '@/components/ui/SDatePicker.vue';
import CtxTimePicker from '@/components/ui/CtxTimePicker.vue';

export default {
  name: 'PushForm',
  directives: { mask },
  components: {
    SDatePicker,
    CtxTimePicker,
  },
  props: {
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
    location: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      validationStatus: [],
      monthRules: [
        (v) => !v.match(/[^\d,]/) || 'Неверный формат',
      ],
      dateNow: this.$dayjs().format('YYYY-MM-DD'),
      maxDate: this.$dayjs('2100-01-01').format('YYYY-MM-DD'),
      conditions,
      actions: [],
      schedules,
      levels: [],
      tasks: [],
      entity: {
        start_at: '',
        end_at: '',
        type: 'NOTIFY',
        duration_min: null,
        expires_hour: null,
        time: '',
        day: '',
        msg_text: '',
        msg_title: '',
        schedule_type: null,
        schedule_week_day: [],
        schedule_month_day: '',
        schedule_date: null,
        schedule_time: '',
        condition_type: null,
        condition_level_id: 0,
        condition_task_id: null,
        condition_idle_time_hour: null,
        condition_start_time_hour: null,
        task_type: 'POOL',
        push_action: '',
        schedule: {},
        condition: {},
        is_active: false,
      },
    };
  },
  computed: {
    сutLevelList() {
      return this.levels.slice(1);
    },
    filteredTaskList() {
      return this.tasks.filter((item) => item.location_id === this.location
        && item.level_id === this.entity.condition_level_id);
    },

    // TODO change to conditionsObject everywhere
    conditionsValue() {
      const keys = Object.keys(this.entity.condition);
      if (keys.length) {
        return keys[0];
      }
      return '';
    },
    schedulesValue() {
      const keys = Object.keys(this.entity.schedule);
      if (keys.length) {
        return keys[0];
      }
      return '';
    },
    conditionsObject() {
      const keys = Object.keys(this.entity.condition);
      if (keys.length) {
        return { type: keys[0], value: this.entity.condition[keys[0]].value };
      }
      return { type: '', value: '' };
    },

    schedulesObject() {
      const keys = Object.keys(this.entity.schedule);
      if (keys.length) {
        return { type: keys[0], value: this.entity.schedule[keys[0]] };
      }
      return { type: '', value: '' };
    },
    maxStartDate() {
      return this.entity.end_at || this.maxDate;
    },
    minEndDate() {
      return this.entity.start_at || this.dateNow;
    },
  },
  watch: {
    conditionsValue(val) {
      this.entity.condition_level_id = parseInt(val, 10);
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },

  created() {
    const location_id = this.location;
    this.$repositories.customer.getCustomerDictionary({ data: { key: [dictionaryKeys.pushAction] } }).then((r) => {
      const { data } = r.data;
      data.push_action.unshift({ label: 'Без действия', value: '' });
      this.actions = data.push_action;
    }).catch((e) => console.warn(e));
    snamiApi.getLevel({ filter: { location_id } }).then((r) => {
      const { data } = r.data;
      this.levels = data.filter((item) => !item.for_tutor);

      if (this.conditionsValue === 'level_id') {
        if (this.entity.condition_level_id === this.levels[0].id) {
          this.entity.condition_level_id = null;
        }
      }
    }).catch((e) => console.warn(e));
    this.$repositories.task.list({ data: { filter: {} } }).then((r) => {
      const { data } = r.data;
      this.tasks = data;
      if (this.conditionsObject.type === 'task_id') {
        const task = data.filter((i) => i.id === this.conditionsObject.value);
        if (task.length) {
          this.entity.condition_level_id = task[0].level_id;
          this.entity.condition_task_id = task[0].id;
        }
      } else {
        this.entity[`condition_${this.conditionsObject.type}`] = this.conditionsObject.value;
      }
    }).catch((e) => console.warn(e));
  },

  mounted() {
    if (this.edit) {
      this.handleEntity();
    } else {
      this.entity.start_at = formatDate(Date.now(), 'YYYY-MM-DD');
      this.entity.day = formatDate();
    }
  },
  methods: {
    validation,
    handleError,
    formatDate,
    generateSchedule(key) {
      const value = this.entity[`schedule_${key}`];
      if (key === 'daily') return { [key]: { time: this.entity.schedule_time } };
      if (key === 'month_day') {
        return {
          [key]: {
            time: this.entity.schedule_time,
            day: value.split(',').map((i) => parseInt(i, 10)),
          },
        };
      }
      if (key === 'month_date') {
        return {
          [key]: {
            time: this.entity.schedule_time,
            day: formatDate(this.entity.schedule_date, 'YYYY-MM-DD'),
          },
        };
      }
      return { [key]: { time: this.entity.schedule_time, day: value } };
    },

    async save() {
      try {
        this.loading = true;
        const data = this.entity;
        const conditionKeys = Object.keys(data.condition);
        const schedulesKeys = Object.keys(data.schedule);
        if (data.condition_idle_time_hour) {
          data.condition_idle_time_hour = Number(data.condition_idle_time_hour);
        }
        if (data.condition_start_time_hour) {
          data.condition_start_time_hour = Number(data.condition_start_time_hour);
        }
        if (data.start_at) {
          data.start_at = formatDate(data.start_at, 'YYYY-MM-DD');
        }
        if (data.end_at) {
          data.end_at = formatDate(data.end_at, 'YYYY-MM-DD');
        }
        if (conditionKeys.length) {
          const key = conditionKeys[0];
          data.condition[key] = { value: this.entity[`condition_${key}`] };
        }

        if (schedulesKeys.length) {
          const key = schedulesKeys[0];
          data.schedule = this.generateSchedule(key);
        }

        data.location_id = this.location;
        if (this.edit) {
          await this.$repositories.event.update({ data });
        } else {
          await this.$repositories.event.create({ data });
        }
        this.handleClose('save');
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
      }
    },

    handleEntity() {
      const { schedule, condition, ...rest } = this.editEntity;
      this.entity = {
        ...this.entity,
        ...rest,
        condition: omitBy(condition, isNil),
        schedule: omitBy(schedule, isNil),
      };
      if (this.schedulesObject.type === 'week_day') {
        this.entity.schedule_time = this.schedulesObject.value.time;
        this.entity.schedule_week_day = this.schedulesObject.value.day;
      }
      if (this.schedulesObject.type === 'month_day') {
        this.entity.schedule_time = this.schedulesObject.value.time;
        this.entity.schedule_month_day = this.schedulesObject.value.day.join(',');
      }
      if (this.schedulesObject.type === 'date') {
        this.entity.schedule_time = this.schedulesObject.value.time;
        this.entity.schedule_date = this.schedulesObject.value.day;
      }
      if (this.schedulesObject.type === 'daily') {
        this.entity.schedule_time = this.schedulesObject.value.time;
      }
    },
    handleClose(e) {
      this.$emit(e || 'close');
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleConditions(value) {
      this.validationStatus = this.validationStatus
        .filter((i) => (i.key !== 'schedule_type') && (i.key !== 'condition_type'));
      if (!value) {
        this.entity.condition = {};
        return false;
      }
      this.entity.condition = { [value]: '' };
      return false;
    },
    handleSchedules(value) {
      this.validationStatus = this.validationStatus
        .filter((i) => (i.key !== 'schedule_type') && (i.key !== 'condition_type'));
      if (!value) {
        this.entity.schedule = {};
        return false;
      }
      this.entity.schedule = { [value]: '' };
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
  .day-of-week-btns{
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;

    button{
      border-radius: 5px !important;
      background-color: #eee !important;
      border: none;
      flex-basis: calc(100% / 8);

      &.v-item--active{
        background-color: #ebeced !important;
      }
    }
  }
</style>
