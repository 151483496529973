<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <h1
              data-test="mail-form-title-form"
              class="tt-text-headline-1 mb-6"
            >
              {{ edit ? 'Редактирование' : 'Добавление' }} письма
            </h1>
            <VRow>
              <VCol cols="12">
                <VForm>
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Название рассылки"
                        placeholder="Новая рассылка"
                        persistent-hint
                        hint="Название будет отображаться только в списке рассылок"
                        :value="item.name"
                        :error="validation('name').status"
                        :error-messages="validation('name').value"
                        data-test="mail-form-input-name"
                        @input="v => handleChange('name',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Тема"
                        placeholder="Новая тема"
                        :value="item.subject"
                        :error="validation('subject').status"
                        :error-messages="validation('subject').value"
                        data-test="mail-form-input-subject"
                        @input="v => handleChange('subject',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="5">
                      <TTSelect
                        v-model="condition"
                        :items="conditionsItems"
                        large
                        label="Условия:"
                        data-test="mail-form-select-condition"
                      />
                    </VCol>
                    <VCol
                      v-if="condition==='content'"
                      data-test="condition-content"
                      cols="7"
                    >
                      <div class="d-flex align-center">
                        <div class="mb-3 tt-text-body-2">
                          Триггер
                        </div>
                        <VMenu
                          v-model="contestHelpMenu"
                          data-test="mail-form-help-menu"
                          content-class="elevation-0"
                          nudge-top="20px"
                          right
                          offset-x
                          :close-on-content-click="false"
                          max-width="340"
                        >
                          <template #activator="{ on, attrs }">
                            <VIcon
                              data-test="mail-form-help-menu"
                              size="19"
                              class="ml-1 mb-3 hint-icon"
                              v-bind="attrs"
                              color="primary"
                              v-on="on"
                            >
                              fal fa-question-circle
                            </VIcon>
                          </template>
                          <VRow class="pa-0 ma-0">
                            <VCol
                              cols="1"
                              class="pa-0 ma-0"
                            >
                              <VCard
                                class="hint-with-triangle"
                                flat
                              />
                            </VCol>
                            <VCol
                              cols="11"
                              class="pa-0 ma-0"
                            >
                              <VCard
                                class="contest_help"
                                flat
                              >
                                <p>
                                  Триггером для отправки письма может быть завершение задачи,
                                  выполнение или невыполнение какой-то ее части.
                                </p>
                              </VCard>
                            </VCol>
                          </VRow>
                        </VMenu>
                      </div>
                      <TTSelect
                        v-model="conditionContent"
                        large
                        placeholder="Выберите пункт"
                        :items="conditionContentList"
                        data-test="mail-form-select-task-content"
                      >
                        <template #item="{item}">
                          <TextTemplate
                            class="py-2"
                            :text="item.text"
                          />
                        </template>
                      </TTSelect>
                    </VCol>
                    <VCol
                      v-if="hasConditionInput"
                      cols="7"
                      style="padding-top: 33px"
                    >
                      <VRow>
                        <VCol>
                          <TTTextField
                            v-mask="'####'"
                            label=" "
                            large
                            :value="timeFrameNumber"
                            placeholder="Введите количество"
                            hide-details="auto"
                            :error="validation(selectedTimeFrame).status"
                            :error-messages="validation(selectedTimeFrame).value"
                            @input="handleTimeChange('timeFrameNumber', $event, selectedTimeFrame)"
                          />
                        </VCol>
                        <VCol>
                          <TTSelect
                            v-model="selectedTimeFrame"
                            attach
                            label=" "
                            large
                            :items="timeFrame"
                            hide-details="auto"
                            @input="handleTimeChange(selectedTimeFrame, $event)"
                          />
                        </VCol>
                      </VRow>
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <h2 class="mb-6 tt-text-title-2">
                    Адресаты
                  </h2>
                  <div
                    v-if="validation('email').status"
                    data-test="mail-form-addressees-error"
                    class="v-messages error--text mt-2"
                    role="alert"
                  >
                    <div class="v-messages__wrapper">
                      <p class="v-messages__message">
                        Добавьте адресатов
                      </p>
                    </div>
                  </div>
                  <VRow>
                    <VCol cols="6">
                      <h3 class="tt-text-body-2">
                        Укажите роли, которым будет адресована рассылка:
                      </h3>
                      <div class="fill-height d-flex flex-column">
                        <VCheckbox
                          :input-value="item.email_hr"
                          :ripple="false"
                          color="tt-black"
                          label="HR"
                          hide-details="auto"
                          data-test="mail-form-checkbox-hr"
                          class="mt-1"
                          @change="v => handleChange('email_hr',v, 'email')"
                        />
                        <VCheckbox
                          :input-value="item.email_mentor"
                          :ripple="false"
                          color="tt-black"
                          label="Руководитель"
                          hide-details="auto"
                          data-test="mail-form-checkbox-mentor"
                          class="mt-1"
                          @change="v => handleChange('email_mentor',v, 'email')"
                        />
                        <VCheckbox
                          :input-value="item.email_tutor"
                          :ripple="false"
                          color="tt-black"
                          label="Наставник"
                          hide-details="auto"
                          data-test="mail-form-checkbox-tutor"
                          class="mt-1"
                          @change="v => handleChange('email_tutor',v, 'email')"
                        />
                        <VCheckbox
                          :input-value="item.email_staff"
                          :ripple="false"
                          color="tt-black"
                          label="Адаптируемый сотрудник"
                          hide-details="auto"
                          data-test="mail-form-checkbox-staff"
                          class="mt-1"
                          @change="v => handleChange('email_staff',v, 'email')"
                        />
                      </div>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Или добавьте email адресатов через запятую:"
                        placeholder="example@yourcompany.ru"
                        :value="item.email"
                        data-test="mail-form-input-addressees-email"
                        @input="v => handleChange('email',v)"
                      />
                    </VCol>
                  </VRow>
                  <VDivider class="mt-8 mb-4" />
                  <h2 class="mb-6 tt-text-title-2">
                    Контент
                  </h2>
                  <VRow>
                    <VCol>
                      <h3 class="mb-3 tt-text-body-2">
                        Подстановки
                      </h3>
                      <SEditor
                        ref="editor"
                        class="foo"
                        height="250"
                        :opts="{
                          placeholder : 'Текст письма'
                        }"
                        :error="validation('body').status || !!globalErrorMessage"
                        :error-messages="validation('body').value || globalErrorMessage"
                        data-test="mail-form-editor-mail-form"
                        @text-change="v => handleChange('body',v)"
                      >
                        <template #default="{handlers}">
                          <VRow>
                            <VCol>
                              <VMenu
                                v-if="condition !== 'start'"
                                attach
                                offset-y
                                bottom
                                content-class="v-menu-shadow limit-height--350"
                                allow-overflow
                                data-test="mail-form-menu-editor"
                              >
                                <template #activator="{ on }">
                                  <TTBtn
                                    color="tt-secondary"
                                    class="mr-5 mb-3 substitution-button"
                                    data-test="mail-form-editor-answers"
                                    depressed
                                    v-on="on"
                                  >
                                    <span class="pr-12">
                                      Ответы
                                    </span>
                                    <VIcon
                                      class="ml-12"
                                      x-small
                                    >
                                      fal fa-chevron-down
                                    </VIcon>
                                  </TTBtn>
                                </template>
                                <VCard
                                  class="v-menu-card"
                                >
                                  <VList class="list">
                                    <VListItem
                                      v-for="(i, index) in answerContentList"
                                      :key="index"
                                      @click="handlers.insertContent(formContent(i))"
                                    >
                                      <VListItemTitle data-test="mail-form-title-editor-task-content">
                                        {{ i.text }}
                                      </VListItemTitle>
                                    </VListItem>
                                  </VList>
                                </VCard>
                              </VMenu>
                              <TTBtn
                                v-for="content in contents"
                                :key="content.text"
                                color="tt-secondary"
                                depressed
                                class="mr-5 mb-3 substitution-button"
                                :data-test="'mail-form-button-editor-' + content.name"
                                @click="handlers.insertContent(content.value)"
                              >
                                {{ content.text }}
                              </TTBtn>
                            </VCol>
                          </VRow>
                        </template>
                      </SEditor>
                    </VCol>
                    <VCol
                      cols="12"
                      class="tt-text-body-2 tt-black--text text--lighten-2 py-0"
                    >
                      <p class="mb-0">
                        Письмо не будет отправлено до тех пор, пока не будут завершены
                        все задачи для указанных в письме ответов.
                      </p>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol class="d-flex justify-start mt-9">
                      <TTBtn
                        large
                        depressed
                        color="tt-secondary"
                        class="mr-6"
                        data-test="mail-form-button-cancel"
                        @click="() => handleClose()"
                      >
                        Отменить
                      </TTBtn>
                      <TTBtn
                        large
                        depressed
                        data-test="mail-form-button-save"
                        @click="save"
                      >
                        {{ edit ? 'Редактировать' : 'Добавить' }} письмо
                      </TTBtn>
                    </VCol>
                  </VRow>
                </VForm>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="mail-form-button-close-form"
              @click="() => handleClose()"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import get from 'lodash/get';
import { mask } from 'vue-the-mask';
import * as snamiApi from '@/services/backend/snami';
import SEditor from '@/components/editor/SEditor.vue';
import { validation, handleError, watchValidationStatus } from '@/services';
import { truncateString } from '@/utils';
import TextTemplate from '@/components/shared/TextTemplate.vue';

const variantDelimiter = '/';

export default {
  name: 'MailForm',
  components: { TextTemplate, SEditor },
  directives: { mask },
  props: {
    location: {
      type: Number,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    editEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contestHelpMenu: false,
      validationStatus: [],
      globalErrorMessage: '',
      item: {
        name: '',
        subject: '',
        body: '',
        email: '',
        location_id: 0,
        type: 'NONE',
        condition: {
          start: {},
        },
      },
      condition: '',
      conditionContentList: [],
      conditionContent: '',
      conditionStart: {},
      answerContentList: [],
      hours: [],
      startDays: [
        { text: 'При добавлении', value: -1 },
        { text: 'День выхода', value: 0 },
        { text: 'За 1 день', value: 1 },
        { text: 'За 2 дня', value: 2 },
        { text: 'За 3 дня', value: 3 },
        { text: 'За 4 дня', value: 4 },
        { text: 'За 5 дней', value: 5 },
        { text: 'За 6 дней', value: 6 },
        { text: 'За неделю', value: 7 },
      ],
      contents: [
        { text: 'Сотрудник', value: '{STAFF}', name: 'staff' },
        { text: 'Имя', value: '{FIRST_NAME}', name: 'first-name' },
        { text: 'Телефон', value: '{PHONE}', name: 'phone' },
        { text: 'Email', value: '{EMAIL}', name: 'email' },
        { text: 'Должность', value: '{POSITION}', name: 'position' },
        { text: 'Зарплата', value: '{SALARY}', name: 'salary' },
        { text: 'Дней отпуска', value: '{VACATION}', name: 'vacation' },
        { text: 'Условия работы/оборудование', value: '{CONDITION}', name: 'condition' },
        { text: 'Руководитель', value: '{MENTOR}', name: 'mentor' },
        { text: 'Наставник', value: '{TUTOR}', name: 'tutor' },
        { text: 'Hr', value: '{HR}', name: 'hr' },
        { text: 'Выходит', value: '{START}', name: 'start' },
      ],
      conditionsItems: [
        { text: 'Без условия', value: '' },
        { text: 'Контент', value: 'content' },
        { text: 'При выходе на работу', value: 'atStart' },
        { text: 'До выхода на работу', value: 'beforeStart' },
        { text: 'После выхода на работу', value: 'afterStart' },
        { text: 'При окончании испытательного срока ', value: 'atProbation' },
        { text: 'До окончания испытательного срока', value: 'beforeProbation' },
        { text: 'После окончания испытательного срока', value: 'afterProbation' },
      ],
      timeFrameNumber: null,
      selectedTimeFrame: 'day',
      timeFrame: [
        // { text: 'Часов', value: 'hour' },
        { text: 'Дней', value: 'day' },
        { text: 'Недель', value: 'week' },
        { text: 'Месяцев', value: 'month' },
      ],
    };
  },
  computed: {
    editor() {
      return this.$refs.editor;
    },
    hasContentCondition() {
      return get(this.item, 'condition.content', false);
    },
    hasStartCondition() {
      return get(this.item, 'condition.start', false);
    },
    hasProbation() {
      return get(this.item, 'condition.probation', false);
    },
    hasConditionInput() {
      return this.condition !== ''
        && this.condition !== 'content'
        && this.condition !== 'atStart'
        && this.condition !== 'atProbation';
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  async created() {
    if (this.edit) {
      this.handleEntity();
      if (this.hasContentCondition) this.condition = 'content';
      if (this.hasStartCondition) {
        if (this.timeFrameNumber === 0 || this.timeFrameNumber === null) {
          this.condition = 'atStart';
        } else if (this.timeFrameNumber < 0) {
          this.condition = 'beforeStart';
        } else {
          this.condition = 'afterStart';
        }
      }
      if (this.hasProbation) {
        if (this.timeFrameNumber < 0) {
          this.condition = 'beforeProbation';
        } else if (this.timeFrameNumber > 0) {
          this.condition = 'afterProbation';
        } else {
          this.condition = 'atProbation';
        }
      }
    }

    try {
      const [{ data: { data: draftLevelList } }, { data: { data: contentList } }] = await Promise.all([
        snamiApi.getLevel({ filter: { location_id: this.location } }),
        this.$repositories.taskContent.list({ data: { filter: { location_id: this.location, type_list: 'ANSWER' } } }),
      ]);
      const levelList = draftLevelList.filter((item) => !item.for_tutor);

      const [{ data: { data: taskList } }, { data: { data: conditionList } }] = await Promise.all([
        this.$repositories.task.list({ data: { filter: { level_id: levelList.map((i) => i.id) } } }),
        this.$repositories.mailing.getCondition({
          data: {
            task_content_id: contentList.map((i) => i.id),
          },
        }),
      ]);

      const levelMap = {};
      const taskMap = {};
      const selectMap = {};
      const conditionMap = {};
      levelList.forEach((item) => { levelMap[item.id] = item; });
      taskList.forEach((item) => { taskMap[item.id] = item; });
      conditionList.forEach((item) => { conditionMap[item.id] = item.conditions; });

      contentList.forEach((item) => {
        const task = taskMap[item.task_id];
        if (!task) return;
        const level = levelMap[task.level_id];
        if (!level) return;
        if (!selectMap[task.id]) {
          selectMap[task.id] = [];
        }
        selectMap[task.id].push({
          value: item.id,
          type: item.type,
          // eslint-disable-next-line max-len,vue/max-len
          text: `${truncateString(level.name, 20)} - ${truncateString(task.name, 30)} - ${item.type} - ${truncateString(item.name, 40)}`,
        });
      });

      levelList.forEach((level) => {
        taskList.forEach((task) => {
          if (task.level_id === level.id && selectMap[task.id] !== undefined) {
            selectMap[task.id].forEach((item) => {
              this.answerContentList.push(item);
              if (conditionMap[item.value]) {
                conditionMap[item.value].forEach((variant) => {
                  this.conditionContentList.push({
                    text: `${item.text} - ${variant.name}`,
                    value: item.value + variantDelimiter + variant.id,
                  });
                });
              }
            });
          }
        });
      });
      levelList.forEach((level) => {
        taskList.forEach((task) => {
          if (task.level_id !== level.id) return;
          this.conditionContentList.push({
            text: `${level.name} - ${task.name} - Завершено`,
            value: `task${variantDelimiter}${task.id}`,
          });
        });
      });
    } catch (e) {
      console.warn(e);
    }
  },
  mounted() {
    // fill quill after it appears in DOM
    if (this.editEntity.body) {
      this.editor.insertRawHtml(this.editEntity.body);
    }
  },
  methods: {
    validation,
    handleError,
    formContent(item) {
      return `{ANSWER:${item.value}#${item.text}}`;
    },
    async save() {
      const data = {
        ...this.item,
        body: this.$refs.editor.getHtml(),
        location_id: this.location,
      };
      if (this.condition === 'afterStart') {
        data.type = 'START';
        data.condition = {
          start: {
            [this.selectedTimeFrame]: Number.parseFloat(this.timeFrameNumber),
          },
        };
      } else if (this.condition === 'afterProbation') {
        data.type = 'PROBATION';
        data.condition = {
          probation: {
            [this.selectedTimeFrame]: Number.parseFloat(this.timeFrameNumber),
          },
        };
      } else if (this.condition === 'beforeStart') {
        data.type = 'START';
        data.condition = {
          start: {
            [this.selectedTimeFrame]: -Math.abs(Number.parseFloat(this.timeFrameNumber)),
          },
        };
      } else if (this.condition === 'beforeProbation') {
        data.type = 'PROBATION';
        data.condition = {
          probation: {
            [this.selectedTimeFrame]: -Math.abs(Number.parseFloat(this.timeFrameNumber)),
          },
        };
      } else if (this.condition === 'atStart') {
        data.type = 'START';
        data.condition = {
          start: {
            day: 0,
            hour: 0,
            month: 0,
            week: 0,
          },
        };
      } else if (this.condition === 'atProbation') {
        data.type = 'PROBATION';
        data.condition = {
          probation: {
            day: 0,
            hour: 0,
            month: 0,
            week: 0,
          },
        };
      } else if (this.condition === 'content' && this.conditionContent) {
        data.type = 'CONTENT';
        const arr = this.conditionContent.split(variantDelimiter);

        if (arr[0] === 'task') {
          data.condition = { content: { task_id: parseInt(arr[1], 10) } };
        } else {
          data.condition = { content: { id: parseInt(arr[0], 10), value: parseInt(arr[1], 10) } };
        }
      } else {
        data.type = 'NONE';
        data.condition = {};
      }
      try {
        if (this.edit) {
          await this.$repositories.mailing.update({ data });
        } else await this.$repositories.mailing.create({ data });
        this.$emit('save');
      } catch (e) {
        this.handleError(e);
      }
    },
    handleClose(e) {
      this.entity = {};
      this.$emit(e || 'close');
    },
    handleChange(name, value, target) {
      this.item[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleTimeChange(name, value, target) {
      this[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleEntity() {
      this.item = { ...this.item, ...this.editEntity };

      if (this.editEntity.type === 'START' && this.editEntity.condition.start) {
        this.conditionStart = { ...this.editEntity.condition.start };
        const timeCondition = Object.entries(this.conditionStart).find(([, value]) => value !== 0);
        if (!timeCondition) {
          this.timeFrameNumber = null;
        } else {
          const [frame, number] = timeCondition;
          this.timeFrameNumber = number;
          this.selectedTimeFrame = frame;
        }
      } else if (this.editEntity.type === 'PROBATION' && this.editEntity.condition.probation) {
        this.conditionStart = { ...this.editEntity.condition.probation };
        const timeCondition = Object.entries(this.conditionStart).find(([, value]) => value !== 0);
        if (!timeCondition) {
          this.timeFrameNumber = null;
        } else {
          const [frame, number] = timeCondition;
          this.timeFrameNumber = number;
          this.selectedTimeFrame = frame;
        }
      } else if (this.editEntity.type === 'CONTENT' && this.editEntity.condition.content) {
        // eslint-disable-next-line vue/max-len,max-len
        if (this.editEntity.condition.content.task_id) {
          this.conditionContent = `task${variantDelimiter}${this.editEntity.condition.content.task_id}`;
        } else {
          this.conditionContent = this.editEntity.condition.content.id + variantDelimiter
            + this.editEntity.condition.content.value;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .contest_help{
    width: 340px;
    padding: 10px;
    border: solid 1px #ddd;
  }
  .substitution-button{
    max-height: 36px !important;
  }
  .list {
    max-height: 400px;
    overflow-y: scroll;
  }
  ::v-deep .v-label {
    font-size: 14px;
  }
</style>
