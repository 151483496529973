export const schedules = [
  { label: 'Без расписания', value: '' },
  { label: 'Каждый день', value: 'daily' },
  { label: 'По дням недели', value: 'week_day' },
  { label: 'По дням месяца', value: 'month_day' },
  { label: 'Конкретная дата', value: 'date' },
];
export const conditions = [
  { label: 'Безусловно', value: '' },
  { label: 'После открытия уровня', value: 'level_id' },
  { label: 'После прохождения задания', value: 'task_id' },
  { label: 'После бездействия, ч', value: 'idle_time_hour' },
  { label: 'После выхода, ч', value: 'start_time_hour' },
];
export const actions = [
  { label: 'Без действия', value: '' },
  { label: 'Переход к списку уровней', value: 'app/levels' },
  { label: 'Переход к чату', value: 'app/chat' },
  { label: 'Переход к справочнику', value: 'app/handbook' },
  { label: 'Переход к профилю', value: 'app/profile' },
  { label: 'Переход к текущему уровню', value: 'app/levels/{level_id}' },
  { label: 'Переход к текущей задаче', value: 'app/levels/{level_id}/tasks/{task_id}' },
];
