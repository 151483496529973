<template>
  <VContainer
    fluid
  >
    <DialogWrapper
      v-model="emailDialog"
      fullscreen
      @click:outside="closeModal('emailDialog')"
    >
      <MailForm
        v-if="emailDialog"
        :location="currentLocationId"
        :edit-entity="activeEntity"
        :edit="edit"
        @close="closeModal('emailDialog')"
        @save="handleSave('mailing')"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="eventDialog"
      fullscreen
    >
      <EventForm
        v-if="eventDialog"
        :location="currentLocationId"
        :edit="edit"
        :edit-entity="activeEntity"
        @close="closeModal('eventDialog')"
        @save="(item) => handleSave('events', item)"
      />
    </DialogWrapper>
    <DialogWrapper
      v-if="pushDialog"
      v-model="pushDialog"
      fullscreen
    >
      <PushForm
        v-if="pushDialog"
        :location="currentLocationId"
        :edit="edit"
        :edit-entity="activeEntity"
        @close="closeModal('pushDialog')"
        @save="handleSave('pushes')"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          {{ deleteFormTitle }}
        </h3>
      </DeleteForm>
    </DialogWrapper>

    <VRow>
      <VCol cols="9">
        <h1 class="tt-text-headline-1">
          События
        </h1>
        <p class="tt-black--text text--lighten-2 mb-4">
          Здесь вы можете настроить разовые или регулярные опросы, оповещения, пуш-напоминания и рассылки.
        </p>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="6"
        class="py-0"
      >
        <TTSelect
          ref="locationDropDown"
          placeholder="Выбор локации"
          data-test="events-select-location"
          :value="currentLocationId"
          :items="locations"
          item-text="name"
          item-value="id"
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
    </VRow>
    <template v-if="currentLocationId">
      <VRow align="center">
        <VCol cols="12">
          <VTabs
            v-model="currentTab"
            slider-color="white"
            color="#000"
            active-class="active-tab-background"
            data-test="events-tabs"
          >
            <VTabsSlider color="#000" />
            <VTab
              to="#tab-task"
              data-test="event-tab-title"
              replace
            >
              Задания ({{ events.length }})
            </VTab>
            <VTab
              replace
              to="#tab-notify"
              data-test="push-tab-title"
            >
              Пуши ({{ pushes.length }})
            </VTab>
            <VTab
              to="#tab-mailing"
              data-test="mailing-tab-title"
              replace
            >
              Рассылки ({{ mailing.length }})
            </VTab>
          </VTabs>
        </VCol>
        <VCol cols="12">
          <VTabsItems v-model="currentTab">
            <VTabItem
              data-test="event-tab-content"
              value="tab-task"
            >
              <TTDataTable
                :key="currentLocationId"
                extra-tall
                :items="events"
                :headers="eventsHeaders"
                hide-default-footer
                :items-per-page="-1"
                data-test="table-event"
              >
                <template #item.type="{}">
                  <div>Опрос</div>
                </template>
                <template #item.name="{item}">
                  <div>
                    {{ item.name }}
                  </div>
                </template>
                <template #item.subject="{item}">
                  <div>
                    {{ getSchedule(item.schedule) }}
                  </div>
                  <p class="tt-black--text text--lighten-2 mb-0">
                    {{ getCondition(item.condition) }}
                  </p>
                </template>
                <template #item.status="{item}">
                  <div class="d-flex align-start justify-space-between">
                    <div>
                      <div class="d-flex align-center">
                        <div>
                          {{ item.status.text }}
                        </div>
                        <VIcon
                          size="16"
                          :color="item.status.color"
                          class="ml-2"
                        >
                          {{ item.status.icon }}
                        </VIcon>
                      </div>
                      <div class="tt-black--text text--lighten-2">
                        <p class="mb-0">
                          {{ formatDate(item.start_at) }}
                          <span v-if="item.end_at">
                            - {{ formatDate(item.end_at) }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="ml-12 d-flex flex-nowrap">
                      <TTBtn
                        v-if="item.has_stat"
                        fab
                        small
                        depressed
                        color="transparent tt-ghost--text"
                        :ripple="false"
                        data-test="event-table-button-goto-analytics-page"
                        @click="gotoAnalyticsPage(item.id)"
                      >
                        <VIcon size="19">
                          fal fa-analytics
                        </VIcon>
                      </TTBtn>
                      <VMenu
                        content-class="v-menu-shadow"
                        offset-y
                        left
                      >
                        <template #activator="{ on }">
                          <TTBtn
                            fab
                            small
                            depressed
                            color="transparent tt-ghost--text"
                            :ripple="false"
                            class="table-menu-button"
                            data-test="table-event-button-show-menu"
                            v-on="on"
                          >
                            <VIcon size="19">
                              fal fa-ellipsis-h
                            </VIcon>
                          </TTBtn>
                        </template>
                        <VCard class="v-menu-card">
                          <VList dense>
                            <VListItem
                              class="custom-menu-item"
                              data-test="table-event-button-goto-content-page"
                              @click="gotoContentPage(item.task_id)"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="tt-black"
                                >
                                  fal fa-list-ul
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent>Посмотреть элементы</VListItemContent>
                            </VListItem>
                            <VListItem
                              data-test="table-event-button-open-event-form"
                              class="custom-menu-item"
                              @click="openModal('eventDialog', item)"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="tt-black"
                                >
                                  fal fa-edit
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent>Редактировать</VListItemContent>
                            </VListItem>
                            <VListItem
                              data-test="table-event-button-open-delete-form"
                              class="custom-menu-item"
                              @click="openDelete(item, 'EVENT')"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="error"
                                >
                                  fal fa-trash-alt
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent class="error--text">
                                Удалить
                              </VListItemContent>
                            </VListItem>
                          </VList>
                        </VCard>
                      </VMenu>
                    </div>
                  </div>
                </template>
              </TTDataTable>
            </VTabItem>
            <VTabItem
              data-test="push-tab-content"
              value="tab-notify"
            >
              <TTDataTable
                :key="currentLocationId"
                extra-tall
                :items="pushes"
                :headers="eventsHeaders"
                hide-default-footer
                :items-per-page="-1"
                data-test="table-push"
              >
                <template #item.type="{}">
                  <div>Пуш</div>
                </template>
                <template #item.name="{item}">
                  <div>
                    {{ item.name }}
                  </div>
                </template>
                <template #item.subject="{item}">
                  <div>
                    {{ getSchedule(item.schedule) }}
                  </div>
                  <p class="tt-black--text text--lighten-2 mb-0">
                    {{ getCondition(item.condition) }}
                  </p>
                </template>
                <template #item.status="{item}">
                  <div class="d-flex align-start justify-space-between">
                    <div>
                      <div class="d-flex align-center">
                        <div>
                          {{ item.status.text }}
                        </div>
                        <VIcon
                          size="16"
                          :color="item.status.color"
                          class="ml-2"
                        >
                          {{ item.status.icon }}
                        </VIcon>
                      </div>
                      <div class="tt-black--text text--lighten-2">
                        <p class="mb-0">
                          {{ formatDate(item.start_at) }}
                          <span v-if="item.end_at">
                            - {{ formatDate(item.end_at) }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="ml-12">
                      <VMenu
                        content-class="v-menu-shadow"
                        offset-y
                        left
                      >
                        <template #activator="{ on }">
                          <TTBtn
                            fab
                            small
                            depressed
                            color="transparent tt-ghost--text"
                            :ripple="false"
                            class="table-menu-button"
                            data-test="table-push-button-show-menu"
                            v-on="on"
                          >
                            <VIcon size="19">
                              fal fa-ellipsis-h
                            </VIcon>
                          </TTBtn>
                        </template>
                        <VCard class="v-menu-card">
                          <VList dense>
                            <VListItem
                              data-test="table-push-button-open-push-form"
                              class="custom-menu-item"
                              @click="openModal('pushDialog', item)"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="tt-black"
                                >
                                  fal fa-edit
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent>Редактировать</VListItemContent>
                            </VListItem>
                            <VListItem
                              data-test="table-push-button-open-delete-form"
                              class="custom-menu-item"
                              @click="openDelete(item, 'PUSH')"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="error"
                                >
                                  fal fa-trash-alt
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent class="error--text">
                                Удалить
                              </VListItemContent>
                            </VListItem>
                          </VList>
                        </VCard>
                      </VMenu>
                    </div>
                  </div>
                </template>
              </TTDataTable>
            </VTabItem>
            <VTabItem
              data-test="events-tab-content"
              value="tab-mailing"
            >
              <TTDataTable
                :key="currentLocationId"
                extra-tall
                :items="mailing"
                :headers="mailingHeaders"
                hide-default-footer
                :items-per-page="-1"
                data-test="table-mail"
              >
                <template #item.type="{}">
                  <div>Рассылка</div>
                </template>
                <template #item.name="{item}">
                  <div>{{ item.name }}</div>
                </template>
                <template #item.subject="{item}">
                  <div v-if="checkStart(item)">
                    <div>
                      {{ item.subject }}
                    </div>
                    <p class="tt-black--text text--lighten-2 mb-0">
                      Условие:
                      {{ conditionStartName(item.condition.start) }}
                    </p>
                  </div>
                  <div v-else-if="checkProbation(item)">
                    <div>
                      {{ item.subject }}
                    </div>
                    <p class="tt-black--text text--lighten-2 mb-0">
                      Условие: {{ conditionProbationName(item.condition.probation) }}
                    </p>
                  </div>
                  <div v-else-if="checkContent(item)">
                    <div>
                      {{ item.subject }}
                    </div>
                    <div class="tt-black--text text--lighten-2">
                      <span v-if="item.condition.content.task_id && item.condition.content.task_name">
                        Условие: Завершить задачу
                      </span>
                      <span v-else-if="item.condition.content.id && item.condition.content.name">
                        Условие: {{ item.condition.content.variant }}
                      </span>
                      <span v-else-if="item.condition.content.id && item.condition.content.name === ''">
                        Условие: [удаленный контент]
                      </span>
                      <br>
                      <p class="mb-0">
                        {{ item.condition.content.level_name }} -
                        {{ item.condition.content.task_name }}
                        <span v-if="item.condition.content.name"> - {{ item.condition.content.name }}</span>
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      {{ item.subject }}
                    </div>
                  </div>
                </template>
                <template #item.email="{item}">
                  <div class="d-flex align-start justify-space-between">
                    <div>
                      {{ formEmailColumn(item) }}
                    </div>
                    <div class="ml-12">
                      <VMenu
                        content-class="v-menu-shadow"
                        offset-y
                        left
                      >
                        <template #activator="{ on }">
                          <TTBtn
                            fab
                            small
                            depressed
                            color="transparent tt-ghost--text"
                            :ripple="false"
                            class="table-menu-button"
                            data-test="table-mail-button-show-menu"
                            v-on="on"
                          >
                            <VIcon size="19">
                              fal fa-ellipsis-h
                            </VIcon>
                          </TTBtn>
                        </template>
                        <VCard class="v-menu-card">
                          <VList dense>
                            <VListItem
                              data-test="table-mail-button-open-mail-form"
                              class="custom-menu-item"
                              @click="openModal('emailDialog', item)"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="tt-black"
                                >
                                  fal fa-edit
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent>Редактировать</VListItemContent>
                            </VListItem>
                            <VListItem
                              data-test="table-mail-button-open-delete-form"
                              class="custom-menu-item"
                              @click="openDelete(item, 'MAILING')"
                            >
                              <VListItemIcon>
                                <VIcon
                                  size="19"
                                  color="error"
                                >
                                  fal fa-trash-alt
                                </VIcon>
                              </VListItemIcon>
                              <VListItemContent class="error--text">
                                Удалить
                              </VListItemContent>
                            </VListItem>
                          </VList>
                        </VCard>
                      </VMenu>
                    </div>
                  </div>
                </template>
              </TTDataTable>
            </VTabItem>
          </VTabsItems>
        </VCol>
      </VRow>
    </template>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h1 class="mb-3 tt-text-headline-1">
          Не задана локация
        </h1>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
/* eslint-disable quote-props */

import { pageLocationToken } from '@/services';
import MailForm from '@/components/forms/MailForm.vue';
import EventForm from '@/components/forms/EventForm.vue';
import PushForm from '@/components/forms/PushForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import { taskTypeTranslate } from '@/constants';
import { formatDate, pluralize } from '@/utils';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'Events',
  components: {
    TextTemplate,
    DialogWrapper,
    PushForm,
    MailForm,
    EventForm,
    DeleteForm,
  },
  inject: ['Names'],
  props: {
    locationId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      deleteFormTitle: '',
      currentTab: '',
      locations: [],
      emailDialog: false,
      eventDialog: false,
      pushDialog: false,
      deleteDialog: false,
      toDeleteId: null,
      toDeleteItemType: '',
      itemToEdit: {},
      events: [],
      pushes: [],
      mailing: [],
      edit: false,
      activeEntity: {},
      currentLocationId: this.locationId,
      eventsHeaders: [
        {
          sortable: false,
          text: 'Тип',
          align: 'left',
          value: 'type',
        },
        {
          sortable: false,
          text: 'Название',
          align: 'left',
          value: 'name',
          width: '40%',
        },
        {
          sortable: false,
          text: 'Расписание',
          align: 'left',
          value: 'subject',
        },
        {
          text: 'Статус и срок',
          align: 'left',
          value: 'status',
          sort: (a, b) => {
            const statusWeight = {
              'Не началось': 1,
              'Отключено': 2,
              'Активное': 3,
              'Архив': 4,
            };
            return statusWeight[a.text] - statusWeight[b.text];
          },
        },
      ],
      mailingHeaders: [
        {
          sortable: false,
          text: 'Тип',
          align: 'left',
          value: 'type',
        },
        {
          sortable: false,
          text: 'Название',
          align: 'left',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Тема',
          align: 'left',
          value: 'subject',
        },
        {
          sortable: false,
          text: 'Email',
          align: 'left',
          value: 'email',
        },
      ],
    };
  },
  created() {
    this.$repositories.location.list({})
      .then((r) => {
        const { data } = r.data;
        this.locations = data;
        const locationIds = this.locations.map((item) => item.id);
        let loc;
        if (this.locationId !== undefined
          && locationIds.indexOf(parseInt(this.locationId, 10)) !== -1) {
          loc = this.locationId;
          pageLocationToken.set(loc);
        } else {
          loc = pageLocationToken.get();
        }
        if (loc) {
          this.currentLocationId = parseInt(loc, 10);
          this.handleLocationChange(loc);
        } else {
          this.$refs.locationDropDown.$children[0].isMenuActive = true;
        }
      })
      .catch((e) => console.warn(e));
  },
  mounted() {
    if (this.$route.hash) {
      this.currentTab = this.$route.hash.substr(1);
    }
    this.$root.$on('createEvent', this.createEvent);
    this.$root.$on('createPush', this.createPush);
    this.$root.$on('createEmail', this.createEmail);
  },
  beforeDestroy() {
    this.$root.$off('createEvent', this.createEvent);
    this.$root.$off('createPush', this.createPush);
    this.$root.$off('createEmail', this.createEmail);
  },
  methods: {
    formatDate,
    /**
     *
     * @param name {String} dialog name to open
     * @param item {Object} item to edit in dialog
     */
    createEvent() {
      this.openModal('eventDialog');
    },
    createPush() {
      this.openModal('pushDialog');
    },
    createEmail() {
      this.openModal('emailDialog');
    },
    openModal(name, item) {
      this[name] = true;
      this.activeEntity = {};
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    closeModal(name) {
      this[name] = false;
      this.emailDialog = false;
      this.eventDialog = false;
      this.pushDialog = false;
      this.edit = false;
      this.activeEntity = {};
    },
    openDelete(item, type) {
      this.deleteDialog = true;
      this.toDeleteId = item.id;
      this.toDeleteItemType = type;
      if (this.toDeleteItemType === 'EVENT') {
        this.deleteFormTitle = 'Удаление события';
      } else if (this.toDeleteItemType === 'PUSH') {
        this.deleteFormTitle = 'Удаление push-уведомления';
      } else {
        this.deleteFormTitle = 'Удаление рассылки';
      }
    },
    async handleDelete() {
      try {
        if (this.toDeleteItemType === 'EVENT' || this.toDeleteItemType === 'PUSH') {
          await this.handleDeleteEvent(this.toDeleteId);
        } else if (this.toDeleteItemType === 'MAILING') {
          await this.handleDeleteMailing(this.toDeleteId);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    async handleDeleteMailing(id) {
      try {
        await this.$repositories.mailing.delete({ data: { id } });
        this.deleteDialog = false;
        this.toDeleteId = 0;
        // всякое другое что нужно сделать
        this.handleLocationChange(this.currentLocationId);
      } catch (e) {
        console.warn(e);
      }
    },
    async handleDeleteEvent(id) {
      try {
        await this.$repositories.event.delete({ data: { id } });
        this.deleteDialog = false;
        this.toDeleteId = 0;
        // всякое другое что нужно сделать
        this.handleLocationChange(this.currentLocationId);
      } catch (e) {
        console.warn(e);
      }
    },
    gotoContentPage(id) {
      this.$router.push({
        name: this.Names.R_EVENT_CONTENT_LIST,
        params: { id },
      });
    },
    gotoEmptyContentPage(id) {
      this.$router.push({
        name: this.Names.R_EVENT_CONTENT_LIST,
        params: { id },
        hash: '#openForm',
      });
    },
    gotoAnalyticsPage(id) {
      this.$router.push({
        name: this.Names.R_EVENTS_ANALYTICS,
        params: { id },
      });
    },
    checkStatus(item) {
      if (!item.is_active) {
        return {
          text: 'Отключено',
          color: '#FF3E00',
          icon: 'fas fa-times-circle',
        };
      }

      const nowDate = new Date();
      const startDate = new Date(item.start_at);
      const endDate = new Date(item.end_at);
      if (startDate > nowDate) {
        return {
          text: 'Не началось',
          color: '#FFB500',
          icon: 'fas fa-clock',
        };
      }
      if (endDate < nowDate) {
        return {
          text: 'Архив',
          color: '#8F9295',
          icon: 'fas fa-caret-circle-down',
        };
      }
      if (startDate < nowDate) {
        return {
          text: 'Активное',
          color: '#00D358',
          icon: 'fas fa-check-circle',
        };
      }

      return {
        text: 'Не началось',
        color: '#FFB500',
        icon: 'fas fa-clock',
      };
    },
    checkStart(item) {
      return item.type === 'START' && item.condition && item.condition.start;
    },
    checkContent(item) {
      return item.type === 'CONTENT' && item.condition && item.condition.content;
    },
    checkProbation(item) {
      return item.type === 'PROBATION' && item.condition && item.condition.probation;
    },

    formEmailColumn(item) {
      return [
        item.email,
        item.email_staff ? '@Сотрудник' : '',
        item.email_hr ? '@HR' : '',
        item.email_mentor ? '@Руководитель' : '',
        item.email_tutor ? '@Наставник' : '',
      ].filter((i) => i !== '')
        .join(', ');
    },
    handleLocation(id) {
      pageLocationToken.set(id);
      if (this.$route.params.locationId) {
        this.$router.push({
          name: this.$route.name,
          params: { locationId: id },
        });
      }
      this.currentLocationId = id;
      this.handleLocationChange(id);
    },

    handleLocationChange(loc) {
      this.$repositories.event.list({ data: { filter: { location_id: loc } } })
        .then((r) => {
          const { data } = r.data;
          if (data === null) {
            this.events = [];
            this.pushes = [];
          } else {
            this.events = data.filter((item) => item.type === 'TASK')
              .map((item) => {
                // eslint-disable-next-line no-param-reassign
                item.status = this.checkStatus(item);
                return item;
              });
            this.pushes = data.filter((item) => item.type === 'NOTIFY')
              .map((item) => {
                // eslint-disable-next-line no-param-reassign
                item.status = this.checkStatus(item);
                return item;
              });
          }
        })
        .catch((e) => console.warn(e));
      this.$repositories.mailing.list({ data: { filter: { location_id: loc } } })
        .then((r) => {
          const { data } = r.data;
          this.mailing = data || [];
        })
        .catch((e) => console.warn(e));
    },

    formPushType(item) {
      if (item.type === 'NOTIFY') return 'PUSH';
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const img = require(`@/assets/images/task_type/${item.task_type}.png`);
      const taskType = taskTypeTranslate[item.type] || item.task_type;
      return {
        img,
        taskType,
        obj: true,
      };
    },

    async handleSave(type, item = {}) {
      if (type === 'events' && !item.id) {
        this.gotoEmptyContentPage(item.task_id);
      }
      this.closeModal();
      let data = [];
      if (type === 'events' || type === 'pushes') {
        ({ data } = await this.$repositories.event
          .list({ data: { filter: { location_id: this.currentLocationId } } }));
      } else {
        ({ data } = await this.$repositories.mailing
          .list({ data: { filter: { location_id: this.currentLocationId } } }));
      }
      if (type === 'events') {
        this[type] = data.data.filter((i) => i.type === 'TASK')
          .map((pushNotify) => ({
            ...pushNotify,
            status: this.checkStatus(pushNotify),
          }));
      } else if (type === 'pushes') {
        this[type] = data.data.filter((i) => i.type === 'NOTIFY')
          .map((pushNotify) => ({
            ...pushNotify,
            status: this.checkStatus(pushNotify),
          }));
      } else {
        this[type] = data.data;
      }
    },

    getSchedule(item = {}) {
      if (item.daily) {
        return `Ежедн. после ${item.daily.time}`;
      }
      if (item.week_day) {
        const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
        const days = [...item.week_day.day].sort();
        return `${days.map((i) => weekDays[i - 1])
          .join(', ')} в ${item.week_day.time}`;
      }
      if (item.month_day) {
        return `${item.month_day.day.join(', ')} числа месяца в ${item.month_day.time}`;
      }
      if (item.date) {
        return `${formatDate(item.date.day)} в ${item.date.time}`;
      }
      return 'Без расписания';
    },
    getCondition(item = {}) {
      if (item.task_id) {
        return 'После задачи';
      }
      if (item.level_id) {
        return 'После открытия уровня';
      }
      if (item.idle_time_hour) {
        return `После простоя в ${item.idle_time_hour.value} часов`;
      }
      if (item.start_time_hour) {
        return `Через ${item.start_time_hour.value} часов после выхода`;
      }
      return 'Безусловно';
    },

    conditionTextTemplate(frame, number, endingBefore, endingAfter) {
      let template;

      switch (frame) {
        case 'week':
          if (number < 0) {
            template = `За ${Math.abs(number)}
              ${pluralize(number, 'неделю', 'недели', 'недель')} ${endingBefore}`;
          } else {
            template = `Через ${number}
              ${pluralize(number, 'неделю', 'недели', 'недель')} ${endingAfter}`;
          }
          break;
        case 'month':
          if (number < 0) {
            template = `За ${Math.abs(number)}
            ${pluralize(number, 'месяц', 'месяца', 'месяца')} ${endingBefore}`;
          } else {
            template = `Через ${number}
            ${pluralize(number, 'месяц', 'месяца', 'месяца')} ${endingAfter}`;
          }
          break;
        case 'day':
        default:
          if (number < 0) {
            template = `За ${Math.abs(number)} ${pluralize(number, 'день', 'дня', 'дней')} ${endingBefore}`;
          } else {
            template = `Через ${number} ${pluralize(number, 'день', 'дня', 'дней')} ${endingAfter}`;
          }
      }
      return template;
    },

    conditionStartName(cond) {
      let ret;
      const timeCondition = Object.entries(cond)
        .find(([, value]) => value !== 0);
      if (!timeCondition) {
        ret = 'День выхода';
      } else {
        const [frame, number] = timeCondition;
        const endingBefore = 'до выхода';
        const endingAfter = 'после выхода';
        ret = this.conditionTextTemplate(frame, number, endingBefore, endingAfter);
      }
      return ret;
    },
    conditionProbationName(cond) {
      let ret;
      const timeCondition = Object.entries(cond)
        .find(([, value]) => value !== 0);
      if (!timeCondition) {
        ret = 'В день окончания испытательного срока';
      } else {
        const [frame, number] = timeCondition;
        const endingBefore = 'до конца испытательного срока';
        const endingAfter = 'после испытательного срока';
        ret = this.conditionTextTemplate(frame, number, endingBefore, endingAfter);
      }
      return ret;
    },
  },
};
</script>

<style>
/* Events */
</style>
