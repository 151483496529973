<template>
  <VContainer
    fluid
    class="pa-4 pb-14"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol>
                <h1
                  class="tt-text-headline-1"
                  data-test="event-form-title-form"
                >
                  {{ edit ? 'Редактирование' : 'Добавление' }} опроса
                </h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VForm
                  v-model="valid"
                >
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Название"
                        placeholder="Укажите название"
                        hint="Будет отображено в приложении и в личном кабинете"
                        persistent-hint
                        :value="event.name"
                        :error="validation('name').status"
                        :error-messages="validation('name').value"
                        data-test="event-form-textarea-name"
                        @change="v => handleChange('name',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        large
                        label="Заголовок push-уведомления"
                        placeholder="Укажите заголовок"
                        hint="Будет отправлен пользователю в пуш-уведомлении"
                        persistent-hint
                        :value="event.msg_title"
                        :error="validation('msg_title').status"
                        :error-messages="validation('msg_title').value"
                        data-test="event-form-textarea-msg-title"
                        @change="v => handleChange('msg_title',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <TTTextarea
                        :maxlength="10000"
                        auto-grow
                        :rows="4"
                        row-height="14"
                        class="mb-2"
                        hide-details="auto"
                        placeholder="Введите текст"
                        label="Текст push-уведомления"
                        :value="event.msg_text"
                        :error="validation('msg_text').status"
                        :error-messages="validation('msg_text').value"
                        data-test="event-form-textarea-msg-text"
                        @input="v => handleChange('msg_text',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <SDatePicker
                        label="Начало"
                        attach
                        class="mb-2"
                        append-icon="fal fa-calendar-alt"
                        placeholder="ДД.ММ.ГГГГ"
                        :value="event.start_at"
                        :error="validation('start_at').status"
                        :error-messages="validation('start_at').value"
                        :min-date="dateNow"
                        :max-date="maxStartDate"
                        single-line
                        hide-details="auto"
                        data-test="event-form-input-data-start-at"
                        @input="v => handleChange('start_at',v)"
                      />
                    </VCol>
                    <VCol>
                      <SDatePicker
                        attach
                        class="mb-2"
                        append-icon="fal fa-calendar-alt"
                        placeholder="ДД.ММ.ГГГГ"
                        label="Окончание"
                        :value="event.end_at"
                        :error="validation('end_at').status"
                        :error-messages="validation('end_at').value"
                        :min-date="minEndDate"
                        :max-date="maxDate"
                        single-line
                        hide-details="auto"
                        data-test="event-form-input-data-end-at"
                        @input="v => handleChange('end_at',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <CtxTimePicker
                        attach
                        append-icon="fal fa-clock"
                        label="Примерное время выполнения"
                        :value="periodToTime(event.duration_min)"
                        :error="validation('duration_min').status"
                        :error-messages="validation('duration_min').value"
                        :minute-interval="1"
                        placeholder="--:--"
                        single-line
                        hide-details="auto"
                        data-test="event-form-input-time-duration-min"
                        @input="v => {
                          const duration_min = timeToPeriod(v);
                          handleChange('duration_min', duration_min);
                        }"
                      />
                    </VCol>
                    <VCol>
                      <TTTextField
                        large
                        label="Актуальность, ч"
                        placeholder="Укажите количество часов"
                        :value="event.expires_hour"
                        :error="validation('expires_hour').status"
                        :error-messages="validation('expires_hour').value"
                        type="number"
                        data-test="event-form-input-expires-hour"
                        @change="v => handleChange('expires_hour',v)"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <VCheckbox
                        v-model="event.is_active"
                        class="mt-0"
                        label="Активная"
                        hide-details="auto"
                        data-test="event-form-checkbox-is-active"
                        :ripple="false"
                      />
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <h2 class="mb-4">
                    Условия
                  </h2>
                  <VRow>
                    <VCol cols="12">
                      <VRow>
                        <VCol
                          cols="6"
                          class="py-0"
                        >
                          <TTSelect
                            :value="event.condition_type"
                            :items="conditionTypeList"
                            large
                            label="Условие"
                            item-text="label"
                            item-value="value"
                            data-test="event-form-select-condition-type"
                            :error="validation('condition_type').status"
                            :error-messages="validation('condition_type').value"
                            @change="v => handleChange('condition_type',v)"
                          />
                        </VCol>
                        <VCol
                          cols="6"
                          class="py-0"
                        >
                          <div class="flex-column">
                            <div
                              v-if="event.condition_type === 'level_id' || event.condition_type === 'task_id'"
                            >
                              <TTSelect
                                v-model="event.condition_level_id"
                                large
                                label="Уровень"
                                placeholder="Выберите уровень"
                                :items="сutLevelList"
                                item-text="label"
                                item-value="value"
                                data-test="event-form-select-condition-level-id"
                              />
                            </div>
                            <div
                              v-if="event.condition_type === 'task_id'"
                              class="mt-6"
                            >
                              <TTSelect
                                v-model="event.condition_task_id"
                                large
                                label="Задание"
                                placeholder="Выберите задание"
                                :items="filteredTaskList"
                                item-text="label"
                                item-value="value"
                                data-test="event-form-select-condition-task-id"
                              />
                            </div>
                            <div
                              v-if="event.condition_type === 'idle_time_hour'"
                              cols="6"
                            >
                              <TTTextField
                                v-mask="'####'"
                                append-icon="fal fa-clock"
                                large
                                label="Время бездействия, ч"
                                placeholder="Укажите количество часов"
                                :value="event.condition_idle_time_hour"
                                :error="validation('condition_idle_time_hour').status"
                                :error-messages="validation('condition_idle_time_hour').value"
                                data-test="event-form-input-condition-idle-time-hour"
                                @change="v => handleChange('condition_idle_time_hour',v)"
                              />
                            </div>
                            <div
                              v-if="event.condition_type === 'start_time_hour'"
                              cols="6"
                            >
                              <TTTextField
                                v-mask="'####'"
                                large
                                label="Время после выхода, ч"
                                placeholder="Укажите количество часов"
                                append-icon="fal fa-clock"
                                :value="event.condition_start_time_hour"
                                :error="validation('condition_start_time_hour').status"
                                :error-messages="validation('condition_start_time_hour').value"
                                data-test="event-form-input-condition-start-time-hour"
                                @change="v => handleChange('condition_start_time_hour',v)"
                              />
                            </div>
                          </div>
                        </VCol>
                      </VRow>
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <h2 class="mb-4">
                    Расписание
                  </h2>
                  <VRow>
                    <VCol cols="12">
                      <VRow>
                        <VCol
                          cols="6"
                          class="py-0"
                        >
                          <TTSelect
                            :value="event.schedule_type"
                            :items="scheduleTypeList"
                            large
                            label="Расписание"
                            item-text="label"
                            item-value="value"
                            data-test="event-form-select-schedule-type"
                            :error="validation('schedule_type').status"
                            :error-messages="validation('schedule_type').value"
                            @change="v => handleChange('schedule_type',v)"
                          />
                        </VCol>
                        <VCol
                          cols="6"
                          class="py-0"
                        >
                          <div class="flex-column">
                            <div
                              v-if="event.schedule_type === 'week_day'"
                              cols="6"
                              class="mb-6"
                            >
                              <div class="mb-3 tt-text-body-2">
                                Дни недели
                              </div>
                              <VBtnToggle
                                class="day-of-week-btns"
                                :value="event.schedule_week_days"
                                tile
                                multiple
                                borderless
                                color="tt-black"
                                data-test="event-form-buttons-schedule-week-day"
                                @change="v => handleChange('schedule_week_days', v, 'day')"
                              >
                                <VBtn
                                  value="1"
                                  text
                                  data-test="button-mon"
                                  class="my-0"
                                >
                                  Пн
                                </VBtn>
                                <VBtn
                                  value="2"
                                  class="my-0"
                                  text
                                  data-test="button-tue"
                                >
                                  Вт
                                </VBtn>
                                <VBtn
                                  value="3"
                                  class="my-0"
                                  text
                                  data-test="button-wed"
                                >
                                  Ср
                                </VBtn>
                                <VBtn
                                  value="4"
                                  class="my-0"
                                  text
                                  data-test="button-thu"
                                >
                                  Чт
                                </VBtn>
                                <VBtn
                                  value="5"
                                  class="my-0"
                                  text
                                  data-test="button-fri"
                                >
                                  Пт
                                </VBtn>
                                <VBtn
                                  value="6"
                                  class="my-0"
                                  text
                                  data-test="button-sat"
                                >
                                  Сб
                                </VBtn>
                                <VBtn
                                  value="7"
                                  class="my-0"
                                  text
                                  data-test="button-sun"
                                >
                                  Вс
                                </VBtn>
                              </VBtnToggle>
                              <div
                                v-if="validation('day').status"
                                class="v-messages error--text mt-2"
                                role="alert"
                                data-test="event-form-error-text-day"
                              >
                                <div class="v-messages__wrapper">
                                  <p class="v-messages__message">
                                    {{ validation('day').value }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="event.schedule_type === 'month_day'"
                              class="mb-6"
                            >
                              <TTTextField
                                large
                                label="Дни месяца, через запятую"
                                placeholder="Укажите дни"
                                :value="event.schedule_month_days"
                                :rules="monthRules"
                                data-test="event-form-input-schedule-month-days"
                                @change="v => handleChange('schedule_month_days',v)"
                              />
                            </div>
                            <div
                              v-if="event.schedule_type === 'date'"
                              class="mb-6"
                            >
                              <SDatePicker
                                label="Дaта"
                                attach
                                placeholder="Укажите дату"
                                :value="event.day"
                                :error="validation('day').status"
                                :error-messages="validation('day').value"
                                :min-date="dateNow"
                                single-line
                                hide-details="auto"
                                data-test="event-form-input-date-day"
                                @input="v => handleChange('day',v)"
                              />
                            </div>
                            <div v-if="!!event.schedule_type">
                              <CtxTimePicker
                                attach
                                label="Время рассылки"
                                placeholder="--:--"
                                append-icon="fal fa-clock"
                                :value="event.time"
                                :minute-interval="1"
                                single-line
                                hide-details="auto"
                                :error="validation('time').status"
                                :error-messages="validation('time').value"
                                data-test="event-form-input-time"
                                @input="v => handleChange('time',v)"
                              />
                            </div>
                          </div>
                        </VCol>
                      </VRow>
                    </VCol>
                  </VRow>
                  <VDivider class="mt-6 mb-4" />
                  <VRow>
                    <VCol class="d-flex justify-start">
                      <TTBtn
                        depressed
                        color="tt-secondary"
                        large
                        class="mr-6"
                        data-test="event-form-button-close"
                        @click.stop="$emit('close')"
                      >
                        Отмена
                      </TTBtn>
                      <TTBtn
                        depressed
                        large
                        data-test="event-form-button-save"
                        :disabled="!valid"
                        @click.stop="save"
                      >
                        {{ edit ? 'Редактировать' : 'Добавить и заполнить' }} задание
                      </TTBtn>
                    </VCol>
                  </VRow>
                </VForm>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="event-form-button-close-form"
              @click.stop="$emit('close')"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
/* eslint-disable camelcase */
import { mask } from 'vue-the-mask';
import * as snamiApi from '@/services/backend/snami';
import { validation, handleError, watchValidationStatus } from '@/services';
import { taskTypeTranslate, dictionaryKeys } from '@/constants';

import SDatePicker from '@/components/ui/SDatePicker.vue';
import CtxTimePicker from '@/components/ui/CtxTimePicker.vue';

const defaultEvent = {
  condition: {},
  duration_min: null,
  end_at: '',
  expires_hour: null,
  id: null,
  location_id: null,
  msg_text: '',
  msg_title: '',
  name: '',
  schedule: {},
  task_id: null,
  task_type: '',
  type: 'TASK',
  is_active: false,
};

export default {
  name: 'EventForm',
  directives: { mask },
  components: {
    SDatePicker,
    CtxTimePicker,
  },
  props: {
    location: {
      type: Number,
      required: true,
    },
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const schedule = this.editEntity.schedule || {};
    const condition = this.editEntity.condition || {};
    let condition_type = '';
    switch (true) {
      case !!condition.task_id:
        condition_type = 'task_id';
        break;
      case !!condition.level_id:
        condition_type = 'level_id';
        break;
      case !!condition.idle_time_hour:
        condition_type = 'idle_time_hour';
        break;
      case !!condition.start_time_hour:
        condition_type = 'start_time_hour';
        break;
      default:
        break;
    }
    let schedule_type = '';
    switch (true) {
      case !!schedule.daily:
        schedule_type = 'daily';
        break;
      case !!schedule.week_day:
        schedule_type = 'week_day';
        break;
      case !!schedule.month_day:
        schedule_type = 'month_day';
        break;
      case !!schedule.date:
        schedule_type = 'date';
        break;
      default:
        break;
    }

    const schedulePrepared = { ...this.editEntity.schedule };

    if (schedulePrepared) {
      Object.keys(schedulePrepared).map((key) => (schedulePrepared[key] == null) && delete schedulePrepared[key]);
    }

    const eventPreset = {
      condition_type,
      schedule_type,

      time: schedulePrepared
        && Object.keys(schedulePrepared).length
        && schedulePrepared[Object.keys(schedulePrepared)[0]]
        ? schedulePrepared[Object.keys(schedulePrepared)[0]].time : '',
      day: schedulePrepared
        && Object.keys(schedulePrepared).length
        && schedulePrepared[Object.keys(schedulePrepared)[0]]
        ? schedulePrepared[Object.keys(schedulePrepared)[0]].day : '',

      schedule_week_days: schedule_type === 'week_day' ? schedule.week_day.day.map((v) => v.toString()) : [],
      schedule_month_days:
        schedule_type === 'month_day' && schedule.month_day.day ? schedule.month_day.day.join(',') : '',
      schedule_date: schedule_type === 'date' ? schedule.date.day : null,
      schedule_time: schedule[schedule_type] ? schedule[schedule_type].time : '',

      condition_level_id: condition.level_id ? condition.level_id.value.toString() : null,
      condition_task_id: condition.task_id ? condition.task_id.value : null,
      condition_idle_time_hour: condition.idle_time_hour ? condition.idle_time_hour.value : null,
      condition_start_time_hour: condition.start_time_hour ? condition.start_time_hour.value : null,

      start_at: this.$dayjs().format('YYYY-MM-DD'),
    };

    return {
      monthRules: [
        (v) => !v.match(/[^\d,]/) || 'Неверный формат',
      ],
      valid: true,
      event: { ...defaultEvent, ...eventPreset, ...this.editEntity },
      validationStatus: [],
      taskTypeList: [],
      levelList: [],
      taskList: [],
      levelId: null,
      dateNow: this.$dayjs().format('YYYY-MM-DD'),
      maxDate: this.$dayjs('2100-01-01').format('YYYY-MM-DD'),

      scheduleTypeList: [
        { label: 'Без расписания', value: '' },
        { label: 'Каждый день', value: 'daily' },
        { label: 'По дням недели', value: 'week_day' },
        { label: 'По дням месяца', value: 'month_day' },
        { label: 'Конкретная дата', value: 'date' },
      ],
      conditionTypeList: [
        { label: 'Безусловно', value: '' },
        { label: 'После открытия уровня', value: 'level_id' },
        { label: 'После прохождения задания', value: 'task_id' },
        { label: 'После бездействия, ч', value: 'idle_time_hour' },
        { label: 'После выхода, ч', value: 'start_time_hour' },
      ],
    };
  },
  computed: {
    сutLevelList() {
      return this.levelList.slice(1);
    },
    filteredTaskList() {
      return this.taskList.filter((item) => item.location_id === this.location
        && item.level_id === this.levelId);
    },
    maxStartDate() {
      return this.event.end_at || this.maxDate;
    },
    minEndDate() {
      return this.event.start_at || this.dateNow;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'event.condition_level_id': function (val) {
      this.levelId = parseInt(val, 10);
      this.getTaskList({ level_id: [val] });
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.$repositories.customer.getCustomerDictionary({
      data: {
        key: [dictionaryKeys.eventTaskType],
      },
    }).then((r) => {
      const { data } = r.data;
      this.taskTypeList = data.event_task_type.map((item) => ({
        value: item,
        // eslint-disable-next-line global-require,import/no-dynamic-require
        img: require(`@/assets/images/task_type/${item}.png`),
        taskType: taskTypeTranslate[item],
      }));

      if ((!this.event.task_type || this.event.task_type === 'NONE') && data.event_task_type.length) {
        // eslint-disable-next-line prefer-destructuring
        this.event.task_type = data.event_task_type[0];
      }
    }).catch((e) => {
      console.warn(e);
    });
    this.$repositories.task.list().then(({ data }) => {
      this.taskList = data.data.map((item) => ({
        label: item.name,
        value: item.id,
        location_id: item.location_id,
        level_id: item.level_id,
      }));
      if (this.event.condition_type === 'task_id') {
        const taskLevel = data.data.filter((i) => i.id === this.event.condition_task_id);
        if (taskLevel.length > 0) {
          // TODO ХЗ что хотели: надо оставить чтото одно, лучше condition_level_id
          this.levelId = taskLevel[0].level_id;
          this.event.condition_level_id = taskLevel[0].level_id.toString();
        }
      }
    }).catch((e) => {
      console.warn(e);
    });
    snamiApi.getLevel({ filter: { location_id: this.location } }).then(({ data }) => {
      this.levelList = data.data.filter((item) => !item.for_tutor).map((item) => ({
        label: `${item.name}`,
        value: item.id.toString(),
      }));
      if (this.event.condition_type === 'level_id') {
        if (this.event.condition_level_id === this.levelList[0].value) {
          this.event.condition_level_id = null;
        } else {
          this.levelId = this.editEntity.condition.level_id && this.editEntity.condition.level_id.value;
        }
      }
    }).catch((e) => {
      console.warn(e);
    });
  },
  methods: {
    validation,
    handleError,
    async getTaskList(filter) {
      try {
        const { data } = await this.$repositories.task.list({ data: { filter } });
        this.taskList = data.data.map((item) => ({
          label: item.name,
          value: item.id,
          location_id: item.location_id,
          level_id: item.level_id,
        }));
      } catch (e) {
        // TODO Обработчик ошибок
        console.log(e);
      }
    },
    handleChange(name, value, target) {
      this.event[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
      if (name === 'condition_type') {
        this.validationStatus = this.validationStatus.filter((i) => i.key !== 'schedule_type');
      }
      if (name === 'schedule_type') {
        this.validationStatus = this.validationStatus.filter((i) => i.key !== 'condition_type');
      }
    },

    periodToTime(period) {
      if (!period) return '';
      const hours = Math.floor(period / 60);
      const min = period % 60;
      return `${(hours < 10 ? '0' : '') + hours}:${(min < 10 ? '0' : '') + min}`;
    },

    timeToPeriod(time) {
      if (!time) return null;
      const [hours, min] = time.split(':');
      return (+hours) * 60 + (+min);
    },

    // сериализовывает входящие данные в требуемые для компонента
    async save() {
      const data = { ...this.event };
      let isError = false;

      switch (data.schedule_type) {
        case 'daily':
          data.schedule = { [data.schedule_type]: { time: data.time } };
          break;
        case 'week_day':
          data.schedule = {
            [data.schedule_type]: {
              day: data.schedule_week_days.map((v) => parseInt(v, 10)),
              time: data.time,
            },
          };
          break;
        case 'month_day':
          if (data.schedule_month_days.match(/[^\d,]/)) {
            this.validationStatus.push({
              key: 'schedule_month_days',
              value: 'INVALID',
            });
            isError = true;
          }
          if (!isError) {
            const days = data.schedule_month_days.split(/\s*,\s*/);
            data.schedule = {
              [data.schedule_type]: {
                day: days.map((v) => parseInt(v, 10)),
                time: data.time,
              },
            };
          }
          break;
        case 'date':
          data.schedule = {
            [data.schedule_type]: {
              day: data.day,
              time: data.time,
            },
          };
          break;
        default:
          data.schedule = {};
      }

      switch (data.condition_type) {
        case 'level_id':
          data.condition = {
            level_id: { value: parseInt(data.condition_level_id, 10) },
          };
          break;
        case 'task_id':
          data.condition = {
            task_id: { value: parseInt(data.condition_task_id, 10) },
          };
          break;
        case 'idle_time_hour':
          data.condition = { idle_time_hour: { value: parseInt(data.condition_idle_time_hour, 10) } };
          break;
        case 'start_time_hour':
          data.condition = { start_time_hour: { value: parseInt(data.condition_start_time_hour, 10) } };
          break;
        default:
          data.condition = {};
      }

      if (isError) {
        return;
      }

      if (data) {
        try {
          data.locationId = data.locationId || this.location;
          let response;
          if (data.id > 0) {
            response = await this.$repositories.event.update({ data });
          } else {
            response = await this.$repositories.event.create({ data });
          }
          if (!data.id) {
            data.task_id = response.data.data.task_id;
          }
          this.$emit('save', data);
        } catch (e) {
          this.handleError(e);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.day-of-week-btns{
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;

  button{
    border-radius: 5px !important;
    background-color: #eee !important;
    border: none;
    flex-basis: calc(100% / 8);

    &.v-item--active{
      background-color: #cfcfcf !important;
    }
  }
}
</style>
